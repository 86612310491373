import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Link from 'next/link';
import React from 'react';
import Slider from 'react-slick';

import { TRUST_PILOT_LINK } from '@common/constants';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';

interface IReviewProps {
  styles: any;
  name: string;
  title: string;
  details: string;
}
const CarouselLayoutContainer = styled.div`
  //// slick-track is the container of all slide items
  .slick-track {
    display: flex;
  }
  // slick slide is the container of a single slide item. There is also a wrapper div before our custom component
  .slick-slide {
    display: flex;
    align-self: stretch;
    height: unset;
    > div {
      display: flex;
      align-self: stretch;
      width: 100%;
    }
  }
`;

const Review = (props: IReviewProps) => {
  const { styles, name, title, details } = props;
  return (
    <div className={cx(styles.review)}>
      <div className={styles.rating_sec}>
        <img src="images/icons/stars.svg" alt="" className={styles.img} />
        <p className={styles.p}>{name}</p>
      </div>
      <div className={styles.review_cntnt}>
        <h4 className={cx(styles.h4, styles.review_head)}>{title}</h4>
        <p className={cx(styles.p, styles.review_desc)}>{details}</p>
      </div>
    </div>
  );
};

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  centerMode: true,
  swipeToSlide: true,
  touchMove: true,
  // prevArrow: <PrevArrow />,
  // nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 10000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TrustPilot = ({ styles }: any) => {
  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        className={cx(styles.slick_next, styles.cstm_slick_arrow)}
        style={{}}
        aria-label="Next button to goto the next review"
        onClick={onClick}>
        <img src="/images/icons/arrow-black.svg" alt="next button" />
      </button>
    );
  }

  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        className={cx(styles.slick_prev, styles.cstm_slick_arrow)}
        style={{}}
        aria-label="Back button to goto the previous review"
        onClick={onClick}>
        <img src="/images/icons/arrow-black.svg" alt="back button" />
      </button>
    );
  }

  return (
    <section className={cx(styles.sec, styles.trustpilot_sec)}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div
            className={cx(styles.heading_blk, styles.text_center, styles.w100)}>
            <h2 className={cx(styles.h2, styles.title)}>
              Our trustpilot reviews
            </h2>
            <Link
              href={TRUST_PILOT_LINK}
              className={styles.link}
              target="_blank">
              <a className={styles.link} target="_blank">
                View on trustpilot{' '}
                <span className={styles.link_arrow}>
                  <img src="images/icons/arrow-red.svg" alt="" />
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <CarouselLayoutContainer
        className={cx(styles.review_slider_wrpr, styles.w100)}>
        <Slider
          {...settings}
          className={cx(styles.review_slider)}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}>
          <Review
            styles={styles}
            name="Mariana"
            title="Very friendly and professional."
            details="The communication is fantastic and the engineers are all very friendly and professional. Super recommended!"
          />
          <Review
            styles={styles}
            name="Christopher"
            title="Great service!."
            details="Excellent timely service with a detailed report including video of the inspection performed. I would highly recommend."
          />
          <Review
            styles={styles}
            name="Ali"
            title="Friendly & Efficient"
            details="Amazing service with the follow ups without any hesitation If the issue persists. Kind communication, friendly service and great expertise!"
          />
          <Review
            styles={styles}
            name="Jessica Mcmillan"
            title="Excellent, friendly service"
            details="Had excellent, friendly service from Qeeper. Kept me up to date with appointment times and were able to fit around my schedule. Had a call after visits to check everything went ok and that I was happy with the service. I highly recommend them."
          />

          <Review
            styles={styles}
            name="Umar"
            title="Great experience."
            details="Had an great experience with this company. They sent an engineer to solve a problem we had in our bathroom. When he came, he recognised the problem right away and solved the issue in no time. He was also kind enough to explain us what was the problem and how to avoid such problem in future."
          />
        </Slider>
      </CarouselLayoutContainer>
    </section>
  );
};

export default TrustPilot;
